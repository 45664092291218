import React, { useRef } from "react";
import "./Home.css";
import FullStackIcon from "../../assets/icons/fullstack.png";
import MobileAppIcon from "../../assets/icons/mobileapp.png";
import WebAppIcon from "../../assets/icons/WebAppIcon.png";
import EcommerceIcon from "../../assets/icons/E-commerceDevelopment.png";
import DigitalMarketingIcon from "../../assets/icons/DigitalMarketingIcon.png";
import ProductDevelopmentIcon from "../../assets/icons/ProductDevelopmentIcon.png";
import HireDevelopersIcon from "../../assets/icons/HireDevelopersIcon.png";
import ManagedServicesIcon from "../../assets/icons/managedservices.png";
import CMSDevelopmentIcon from "../../assets/icons/CMSDevelopment.png";
import DiscoveryIcon from "../../assets/processicon/discovery.png";
import PlanningIcon from "../../assets/processicon/planning.png";
import DesignIcon from "../../assets/processicon/design.png";
import DevelopmentIcon from "../../assets/processicon/development.png";
import TestingIcon from "../../assets/processicon/testing.png";
import LaunchIcon from "../../assets/processicon/launch.png";
import SupportIcon from "../../assets/processicon/support.png";
import TechImage1 from "../../assets/technology/ASP.NET-logo.png";
import TechImage2 from "../../assets/technology/Azure-logo.png";
import TechImage3 from "../../assets/technology/CSS3_logo.png";
import TechImage4 from "../../assets/technology/Go_Logo.png";
import TechImage5 from "../../assets/technology/HTML5-logo.png";
import TechImage6 from "../../assets/technology/ISO_C_logo.png";
import TechImage7 from "../../assets/technology/PHP-logo.png";
import TechImage8 from "../../assets/technology/Python-logo.png";
import TechImage9 from "../../assets/technology/React-logo.png";
import TechImage10 from "../../assets/technology/Ruby_on_Rails-logo.png";
import TechImage11 from "../../assets/technology/android-logo.png";
import TechImage12 from "../../assets/technology/aws-logo.png";
import TechImage13 from "../../assets/technology/csharp-logo.png";
import TechImage14 from "../../assets/technology/cybrain-AEM.png";
import TechImage15 from "../../assets/technology/cybrain-AI.png";
import TechImage16 from "../../assets/technology/cybrain-Adobe-Campaign.png";
import TechImage17 from "../../assets/technology/cybrain-Trello.png";
import TechImage18 from "../../assets/technology/cybrain-adobe-marketo.png";
import TechImage19 from "../../assets/technology/cybrain-adobe-target.png";
import TechImage20 from "../../assets/technology/cybrain-machine-learning.png";
import TechImage21 from "../../assets/technology/cybrain-rasa.png";
import TechImage22 from "../../assets/technology/devops-logo.png";
import TechImage23 from "../../assets/technology/erp-crm-logo.png";
import TechImage24 from "../../assets/technology/flutter-logo.png";
import TechImage25 from "../../assets/technology/ios-logo.png";
import TechImage26 from "../../assets/technology/java-logo.png";
import TechImage27 from "../../assets/technology/laravel-logo.png";
import TechImage28 from "../../assets/technology/magento-logo.png";
import TechImage29 from "../../assets/technology/mean-logo.png";
import TechImage30 from "../../assets/technology/mern-logo.png";
import TechImage31 from "../../assets/technology/mysql-logo.png";
import TechImage32 from "../../assets/technology/nodejs-logo.png";
import TechImage33 from "../../assets/technology/shopify-logo.png";
import TechImage34 from "../../assets/technology/spring-logo.png";
import TechImage35 from "../../assets/technology/swift-logo.png";
import TechImage36 from "../../assets/technology/testing-icon-logo.png";
import TechImage37 from "../../assets/technology/vuejs-logo.png";
import imm from "../../assets/why.jpg";
import imm2 from "../../assets/why2.jpg";
import a from "../../assets/whyaspencask/client.png";
import b from "../../assets/whyaspencask/DedicatedTeam.png";
import c from "../../assets/whyaspencask/industry.png";
import d from "../../assets/whyaspencask/Innovation&Technology.png";

export const Home: React.FC = () => {
  const servicesRef = useRef<HTMLDivElement>(null);

  const handleDiscoverMore = () => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="home">
      {/* Hero Section */}
      <section className="hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Welcome to AspenCask</h1>
          <p>
            We develop world-class websites and apps tailored to your needs.
          </p>
          <button className="hcta" onClick={handleDiscoverMore}>
            Discover More
          </button>
        </div>
      </section>

      {/* Services Section */}
      <section className="homeservices" ref={servicesRef}>
        <h2>Our Services</h2>
        <div className="homeservice-items">
          <div className="homeservice-item">
            <div className="icon"><img src={FullStackIcon} alt="Full Stack Development Icon" /></div>
            <h3>Full Stack Development</h3>
            <p>
              We have amassed unmatched expertise dealing with both front-end and back-end technologies for full-stack development.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={MobileAppIcon} alt="Mobile App Development Icon" /></div>
            <h3>Mobile App Development</h3>
            <p>
              We build superior mobile apps or revamp your existing mobile application with customizable project modifications.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={WebAppIcon} alt="Web Application Development Icon" /></div>
            <h3>Web Application Development</h3>
            <p>
              Our web design top-tier web development company provides excellent web services aimed at enhancing our clients’ online visibility.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={EcommerceIcon} alt="E-commerce Development Icon" /></div>
            <h3>E-commerce Development</h3>
            <p>
              We develop scalable solutions with excellent user experiences, smart designs, robust and seamless online E-commerce.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={DigitalMarketingIcon} alt="Digital Marketing Services Icon" /></div>
            <h3>Digital Marketing Services</h3>
            <p>
              Our expert team will elevate your digital presence, ensuring your brand shines brilliantly in the online business success.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={ProductDevelopmentIcon} alt="Product Development Icon" /></div>
            <h3>Product Development</h3>
            <p>
              Develop your custom digital product and business offering with our trusted, reliable Product development services.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={HireDevelopersIcon} alt="Hire Dedicated Developers Icon" /></div>
            <h3>Hire Dedicated Developers</h3>
            <p>
              Hire dedicated offshore developers for startups or enterprises and receive expert technical guidance throughout the industries.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={ManagedServicesIcon} alt="Managed Services Icon" /></div>
            <h3>Managed Services</h3>
            <p>
              Enhance business operations with Managed IT services, ensuring efficient technology management and peak performance online.
            </p>
          </div>
          <div className="homeservice-item">
            <div className="icon"><img src={CMSDevelopmentIcon} alt="CMS Development Icon" /></div>
            <h3>CMS Development</h3>
            <p>
              As a CMS development top-notch CMS solutions offer rich, user-friendly experiences to resolve business issues.
            </p>
          </div>
        </div>
      </section>

      {/* Technology Images Section */}
      <section className="tech-images">
        <h2>Technologies We Use</h2>
        <div className="tech-images-container">
          <div className="tech-item">
            <img src={TechImage1} alt="ASP.NET" />
            <p>ASP.NET</p>
          </div>
          <div className="tech-item">
            <img src={TechImage2} alt="Azure" />
            <p>Azure</p>
          </div>
          <div className="tech-item">
            <img src={TechImage3} alt="CSS3" />
            <p>CSS3</p>
          </div>
          <div className="tech-item">
            <img src={TechImage4} alt="Go" />
            <p>Go</p>
          </div>
          <div className="tech-item">
            <img src={TechImage5} alt="HTML5" />
            <p>HTML5</p>
          </div>
          <div className="tech-item">
            <img src={TechImage6} alt="ISO C" />
            <p>ISO C</p>
          </div>
          <div className="tech-item">
            <img src={TechImage7} alt="PHP" />
            <p>PHP</p>
          </div>
          <div className="tech-item">
            <img src={TechImage8} alt="Python" />
            <p>Python</p>
          </div>
          <div className="tech-item">
            <img src={TechImage9} alt="React" />
            <p>React</p>
          </div>
          <div className="tech-item">
            <img src={TechImage10} alt="Ruby on Rails" />
            <p>Ruby on Rails</p>
          </div>
          <div className="tech-item">
            <img src={TechImage11} alt="Android" />
            <p>Android</p>
          </div>
          <div className="tech-item">
            <img src={TechImage12} alt="AWS" />
            <p>AWS</p>
          </div>
          <div className="tech-item">
            <img src={TechImage13} alt="C#" />
            <p>C#</p>
          </div>
          <div className="tech-item">
            <img src={TechImage14} alt="AEM" />
            <p>AEM</p>
          </div>
          <div className="tech-item">
            <img src={TechImage15} alt="AI" />
            <p>AI</p>
          </div>
          <div className="tech-item">
            <img src={TechImage16} alt="Adobe Campaign" />
            <p>Adobe Campaign</p>
          </div>
          <div className="tech-item">
            <img src={TechImage17} alt="Trello" />
            <p>Trello</p>
          </div>
          <div className="tech-item">
            <img src={TechImage18} alt="Adobe Marketo" />
            <p>Adobe Marketo</p>
          </div>
          <div className="tech-item">
            <img src={TechImage19} alt="Adobe Target" />
            <p>Adobe Target</p>
          </div>
          <div className="tech-item">
            <img src={TechImage20} alt="Machine Learning" />
            <p>Machine Learning</p>
          </div>
          <div className="tech-item">
            <img src={TechImage21} alt="Rasa" />
            <p>Rasa</p>
          </div>
          <div className="tech-item">
            <img src={TechImage22} alt="DevOps" />
            <p>DevOps</p>
          </div>
          <div className="tech-item">
            <img src={TechImage23} alt="ERP CRM" />
            <p>ERP CRM</p>
          </div>
          <div className="tech-item">
            <img src={TechImage24} alt="Flutter" />
            <p>Flutter</p>
          </div>
          <div className="tech-item">
            <img src={TechImage25} alt="iOS" />
            <p>iOS</p>
          </div>
          <div className="tech-item">
            <img src={TechImage26} alt="Java" />
            <p>Java</p>
          </div>
          <div className="tech-item">
            <img src={TechImage27} alt="Laravel" />
            <p>Laravel</p>
          </div>
          <div className="tech-item">
            <img src={TechImage28} alt="Magento" />
            <p>Magento</p>
          </div>
          <div className="tech-item">
            <img src={TechImage29} alt="MEAN" />
            <p>MEAN</p>
          </div>
          <div className="tech-item">
            <img src={TechImage30} alt="MERN" />
            <p>MERN</p>
          </div>
          <div className="tech-item">
            <img src={TechImage31} alt="MySQL" />
            <p>MySQL</p>
          </div>
          <div className="tech-item">
            <img src={TechImage32} alt="Node.js" />
            <p>Node.js</p>
          </div>
          <div className="tech-item">
            <img src={TechImage33} alt="Shopify" />
            <p>Shopify</p>
          </div>
          <div className="tech-item">
            <img src={TechImage34} alt="Spring" />
            <p>Spring</p>
          </div>
          <div className="tech-item">
            <img src={TechImage35} alt="Swift" />
            <p>Swift</p>
          </div>
          <div className="tech-item">
            <img src={TechImage36} alt="Testing" />
            <p>Testing</p>
          </div>
          <div className="tech-item">
            <img src={TechImage37} alt="Vue.js" />
            <p>Vue.js</p>
          </div>
        </div>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-aspencask">
      <div className="content">
        <h2>
          Why AspenCask?
        </h2>
        <p>
          AspenCask Solution LLP is a leading software development company,
          providing <b>cutting-edge digital solutions</b> with expertise in web
          development, AI, cloud computing, and enterprise software. We focus
          on <b>innovation, scalability, and high-performance solutions</b> to
          transform businesses.
        </p>
        <div className="features">
          <div className="feature">
            <div className="icon black-bg">
              <img src={c} alt="Industry Experience" />
            </div>
            <div>
              <h3>Industry Expertise</h3>
              <p>We bring <b>years of experience</b> in <b>full-cycle software development</b>.</p>
            </div>
          </div>
          <div className="feature">
            <div className="icon red-bg">
              <img src={b} alt="Dedicated Team" />
            </div>
            <div>
              <h3>Dedicated Team</h3>
              <p>Our skilled professionals specialize in <b>cutting-edge technologies & frameworks</b>.</p>
            </div>
          </div>
          <div className="feature">
            <div className="icon black-bg">
              <img src={a} alt="Client-Centric" />
            </div>
            <div>
              <h3>Client-Centric Approach</h3>
              <p>We prioritize business needs with <b>custom, scalable solutions</b>.</p>
            </div>
          </div>
          <div className="feature">
            <div className="icon red-bg">
              <img src={d} alt="Innovation & Technology" />
            </div>
            <div>
              <h3>Innovation & Technology</h3>
              <p>We integrate AI, cloud, and automation<b> to enhance business efficiency</b>.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="image-container">
        <img className="main-img" src={imm} alt="Technology Strategy" />
        <img className="small-img" src={imm2} alt="Business Growth" />
        
      </div>
    </section>

      {/* Our Process Section */}
      <section className="our-process">
        <h2>Our Process</h2>
        <div className="process-steps">
          <div className="process-step">
            <div className="icon"><img src={DiscoveryIcon} alt="Discovery Icon" /></div>
            <h3>1. Discovery</h3>
            <hr className="line" />
            <p>
              We start by understanding your business goals, target audience,
              and unique challenges.
            </p>
          </div>
          <div className="process-step">
            <div className="icon"><img src={PlanningIcon} alt="Planning Icon" /></div>
            <h3>2. Planning</h3>
            <hr className="line" />
            <p>
              Our team crafts a detailed project plan outlining timelines,
              deliverables, and key milestones.
            </p>
          </div>
          <div className="process-step">
            <div className="icon"><img src={DesignIcon} alt="Design Icon" /></div>
            <h3>3. Design</h3>
            <hr className="line" />
            <p>
              We create prototypes and design mockups reflecting your brand
              identity.
            </p>
          </div>
          <div className="process-step">
            <div className="icon"><img src={DevelopmentIcon} alt="Development Icon" /></div>
            <h3>4. Development</h3>
            <hr className="line" />
            <p>
              Our skilled developers bring the designs to life, employing the
              latest technologies.
            </p>
          </div>
          <div className="process-step">
            <div className="icon"><img src={TestingIcon} alt="Testing Icon" /></div>
            <h3>5. Testing</h3>
            <hr className="line" />
            <p>
              Rigorous testing is conducted to ensure everything works
              flawlessly before launch.
            </p>
          </div>
          <div className="process-step">
            <div className="icon"><img src={LaunchIcon} alt="Launch Icon" /></div>
            <h3>6. Launch</h3>
            <hr className="line" />
            <p>
              We deploy your project with a focus on a smooth transition to the
              live environment.
            </p>
          </div>
          <div className="process-step">
            <div className="icon"><img src={SupportIcon} alt="Support Icon" /></div>
            <h3>7. Support</h3>
            <hr className="line" />
            <p>
              We provide ongoing support and updates to help you adapt to
              changing market demands.
            </p>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials">
        <h2>What Our Clients Say</h2>
        <div className="testimonial-items">
          <div className="testimonial-item">
            <p>"AspenCask transformed our vision into reality."</p>
            <span>- Gurmeet Gandhi</span>
          </div>
          <div className="testimonial-item">
            <p>"Professional, timely, and highly skilled."</p>
            <span>- Sumit Kumar</span>
          </div>
          <div className="testimonial-item">
            <p>"Our go-to partner for development needs."</p>
            <span>- Rahul Kumar</span>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2>Ready to start your project?</h2>
        <a href="/contact" className="cta">
          Get in Touch
        </a>
      </section>
    </div>
  );
};
