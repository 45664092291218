import React from "react";
import compltedimage from "../../assets/completed1.jpg";
import minicompletdimage from "../../assets/completed2.jpg";
import inprogressimage from "../../assets/progress2.png";
import miniprogressimage from "../../assets/progress2.jpg";
import "./Portfolio.css";

export const PortfolioPage: React.FC = () => {
  return (
    <div className="portfolio-page-wrapper">
      <section className="hero">
        <div className="hero-overlay"></div>
        <div className="hero-content">
          <h1>Our Portfolio</h1>
          <p>
            Explore our extensive portfolio showcasing a diverse range of projects we have successfully delivered.
          </p>
        </div>
      </section>

      {/* Completed Projects Section */}
      <section className="completed-projects-section">
        <h2>Completed Projects</h2>
        <hr className="line" />
        <div className="portfolio-project-info">
          <div className="portfolio-images">
            <img src={compltedimage} alt="Completed Projects" className="portfolio-image-big" />
            <img src={minicompletdimage} alt="Completed Projects" className="portfolio-image-small" />
          </div>
          <div className="portfolio-content">
            <h3>Over 50 Completed Projects</h3>
            <p>
              Our team has successfully delivered over 50 projects across various industries, ensuring high-quality results and client satisfaction. From innovative web applications to robust mobile solutions, our portfolio reflects our commitment to excellence and our ability to adapt to the unique needs of each client.
            </p>
            <p>
              We take pride in our meticulous approach to project management, ensuring that every project is delivered on time and within budget. Our portfolio includes projects in sectors such as healthcare, e-commerce, real estate, and more, each showcasing our technical expertise and creative problem-solving skills.
            </p>
            <p>
              Our completed projects are a testament to our dedication to quality and our ability to deliver solutions that drive business success. We invite you to explore our portfolio and see for yourself the impact of our work.
            </p>
          </div>
        </div>
      </section>

      {/* In Progress Projects Section */}
      <section className="in-progress-projects-section">
        <h2>Projects in Progress</h2>
        <hr className="line" />
        <div className="portfolio-project-info">
          <div className="portfolio-images">
            <img src={inprogressimage} alt="In Progress Projects" className="portfolio-image-big" />
            <img src={miniprogressimage} alt="In Progress Projects" className="portfolio-image-small" />
          </div>
          <div className="portfolio-content">
            <h3>Currently Working on 10 Projects</h3>
            <p>
              We are currently engaged in 10 exciting projects, pushing the boundaries of innovation and excellence. Our ongoing projects span a variety of industries and involve cutting-edge technologies and methodologies.
            </p>
            <p>
              Each project is a new challenge that allows us to expand our expertise and deliver solutions that exceed our clients' expectations. We are committed to continuous improvement and staying ahead of industry trends to provide the best possible outcomes for our clients.
            </p>
            <p>
              Our in-progress projects are a reflection of our forward-thinking approach and our dedication to delivering value through technology. Stay tuned for updates on these exciting initiatives as we bring them to fruition.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="portfolio-cta-section">
        <h2>Want to Start a Project?</h2>
        <p>
          We are ready to bring your vision to life with our expert team of developers and designers. Whether you have a clear idea of what you need or are looking for guidance on how to achieve your goals, we are here to help.
        </p>
        <p>
          Our collaborative approach ensures that we understand your unique requirements and deliver solutions that are tailored to your business. From initial consultation to final delivery, we work closely with you to ensure that your project is a success.
        </p>
        <p>
          Contact us today to discuss your project and learn how we can help you achieve your objectives. We look forward to partnering with you and creating something amazing together.
        </p>
        <a href="/contact" className="portfolio-cta-button">
          Contact Us
        </a>
      </section>
    </div>
  );
};
