import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom"; // Import useLocation for active link handling
import "./SideHeader.css";
import { MenuType } from "./model/sideHeader.model";
import logo from "../../assets/logo.png";

const SideHeader: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  // Use React Router's useLocation to get the current path
  const location = useLocation();

  // Toggle the menu on click
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Close menu when a link is clicked
  const closeMenu = () => {
    setIsOpen(false);
  };

  // Check if the current path matches the link

  const links: MenuType[] = [
    {
      to: "/",
      onCLick: closeMenu,
      title: "Home",
    },

    {
      to: "/portfolio",
      onCLick: closeMenu,
      title: "Portfolio",
    },
    {
      to: "/services",
      onCLick: closeMenu,
      title: "Services",
    },
    {
      to: "/contact",
      onCLick: closeMenu,
      title: "Contact",
    },
    {
      to: "/about",
      onCLick: closeMenu,
      title: "About-Us",
    },
    
  ];

  return (
    <>
      {/* Fixed header for mobile and desktop */}
      <header className="header">
        <div className="header-container">
          {/* Logo and company name with homepage link */}
          <h1 className="logo">
            <Link to={"/"} className="logo-link">
              <img
                src={logo}
                alt="AspenCask Logo"
                className="logo-img"
              />
            </Link>
          </h1>

          {/* Navigation Links */}
          <nav className={`nav-links ${isOpen ? "open" : ""}`}>
            <ul>
              {links.map(({ onCLick, title, to }) => (
                <li>
                  <NavLink
                    to={to}
                    onClick={onCLick}
                    className={({ isActive }) =>
                      isActive ? "active-link" : ""
                    }
                  >
                    {title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </nav>

          {/* Hamburger Menu */}
          <div className="menu-toggle" onClick={toggleMenu}>
            {[1, 2, 3].map((n) => (
              <span key={n} className="hamburger"></span>
            ))}
          </div>
        </div>
      </header>

      {/* Main content */}
      <div className="main-content">{/* Your page content goes here */}</div>
    </>
  );
};

export default SideHeader;
